import React from 'react';
import './MerchantPortal.scss';
import MerchantEmpty from 'components/MerchantPortal/MerchantEmpty/MerchantEmpty';
import useMerchantsMock from 'hooks/useMerchantsMock';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { useRouteMatch, useHistory } from 'react-router-dom';

const CLASS_PAGE_LOADER = 'merchant-portal-page-loader';
const CLASS_MAIN = 'merchant-portal';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  EMPTY_CONTAINER: `${CLASS_MAIN}__empty-container`,
};

type MerchantRouteParams = {
  pid: string;
};

const MerchantPortal: React.FC<React.ComponentProps<'div'>> = () => {
  const { pid: routeProjectId } = useRouteMatch<MerchantRouteParams>().params;
  const history = useHistory();
  const { merchantsData, areMerchantsLoading } = useMerchantsMock();

  if (areMerchantsLoading) {
    return (
      <div className={CLASS_PAGE_LOADER}>
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className={CLASS_MAIN}>
      <h2>{`Merchants`}</h2>
      {merchantsData.length ? (
        merchantsData.map((merchant, index) => (
          <div className={CLASSES.BODY} key={index}>
            <ul>
              <li>
                <b>{`Merchant ${index + 1}`}</b>
                <ul>
                  <li>{`Id: ${merchant.id}`}</li>
                  <li>{`Name: ${merchant.name}`}</li>
                  <li>{`Address: ${merchant.addressLine}`}</li>
                  <li>{`City: ${merchant.addressCity}`}</li>
                  <li>{`Contact Email: ${merchant.contactEmail}`}</li>
                  <li>
                    <button
                      onClick={() => {
                        history.push(`/project/${routeProjectId}/merchant-portal/${merchant.id}`);
                      }}
                    >
                      Offers
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <div className={CLASSES.EMPTY_CONTAINER}>
          <MerchantEmpty />
        </div>
      )}
    </div>
  );
};
export default MerchantPortal;
