import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { FlightDateRangePicker, FlightTable, FlightTextInput, getIcon } from '@flybits/design-system';
import { useQuery } from '@tanstack/react-query';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { Content, ContentTemplate } from 'interface/content/content.interface';
import { CLASSES } from '../constants';
import { AdvancedDateRange, DateRange } from '../types';
import './Engagement.scss';
import useDebounce from 'hooks/useDebounce';
import FilterByLabel from 'components/Analytics/FilterByLabel/FilterByLabel';
import { LABEL_RULE } from 'components/Analytics/types';

const tableHeaders = [
  {
    name: 'key',
    key: 'key',
    isVisible: false,
    isSortable: false,
  },
  {
    name: 'Name',
    key: 'name',
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Type',
    key: 'type',
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Total Views',
    key: 'total_views',
    isVisible: true,
    isSortable: true,
    tooltip: 'The total number of times this particular content item has been viewed.',
  },
  {
    name: 'Unique views',
    key: 'unique_views',
    isVisible: true,
    isSortable: true,
    tooltip: 'The number of unique users who have viewed this particular content item.',
  },
  {
    name: 'Unique engagement',
    key: 'unique_engagements',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The number of unique users who have “engaged” a certain action. Engagement differs per content item type, depending on how your team has defined it per content item.',
  },
  {
    name: 'Unique engagement rate',
    key: 'unique_engagement_rate',
    isVisible: true,
    isSortable: true,
    tooltip: 'Unique engagement divided by unique views.',
  },
  {
    name: 'Unique fulfillment',
    key: 'unique_fulfillment',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The number of unique users who have “fulfilled” a certain action. Fulfillment differs per content item type, depending on how your team has defined it per content item. ',
  },
  {
    name: 'Unique fulfillment rate',
    key: 'unique_fulfillment_rate',
    isVisible: true,
    isSortable: true,
    tooltip: 'Unique fulfillments divided by unique views.',
  },
];

const fetchContents = async (
  analyticsExperiencesApi: AnalyticsExperiencesAPI,
  page: number,
  perPage: number,
  dateRange: DateRange<Date>,
  search: string,
  labels: string,
  sortBy: string,
  sortOrder: string,
) => {
  const res = await analyticsExperiencesApi.getContentsEngagement({
    lastFrom: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
    lastTo: Math.trunc((dateRange[1]?.getTime() ?? new Date().getTime()) / 1000),
    offset: (page - 1) * perPage,
    limit: perPage,
    search,
    labelsFormula: labels ? `(${labels})` : undefined,
    sortBy,
    sortOrder,
  });
  // we should catch the error, check status using isAxiosError and e.status,
  // then determining what to do from there, but for this first iteration it's fine
  const contentList: Content[] = [];
  for (let i = 0; i < (res?.data?.length || 0); i++) {
    try {
      const contentRes = await analyticsExperiencesApi.getContent(res.data[i].contentId);
      contentList.push(contentRes);
    } catch (e) {}
  }

  const templateList: ContentTemplate[] = [];
  for (let i = 0; i < (contentList.length || 0); i++) {
    const content = contentList[i];
    if (content && content.templateId) {
      try {
        const templateRes = await analyticsExperiencesApi.getTemplate(content.templateId);
        templateList.push(templateRes);
      } catch (e) {}
    }
  }

  return {
    contentsEngagement: res.data || [],
    contentsData: contentList.length > 0 ? await Promise.all(contentList) : [],
    templates: templateList.length > 0 ? await Promise.all(templateList) : [],
    pagination: res.pagination,
  };
};

const Contents = () => {
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 0);

  const { languages } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<AdvancedDateRange>([startOfToday, new Date(), 0, 'day']);
  const [queryDateRange, setQueryDateRange] = useState<AdvancedDateRange>([startOfToday, endOfDay]);
  const [search, setSearch] = useState('');
  const [labels, setLabels] = useState<string[]>([]);
  const [labelRule, setLabelRule] = useState<LABEL_RULE>();
  const labelString = labelRule === LABEL_RULE.CONTAINS_ALL_OF ? labels.join(',') : labels.join(';');
  const [sortByColumn, setSortByColumn] = useState('total_views');
  const [sortOrder, setSortOrder] = useState('desc');
  const debouncedSearch = useDebounce(search, 300);

  const { data, status, isFetching, isLoading } = useQuery({
    queryKey: [
      'contents-engagement',
      currentPage,
      perPage,
      queryDateRange,
      debouncedSearch,
      labelString,
      sortByColumn,
      sortOrder,
    ],
    queryFn: () =>
      fetchContents(
        analyticsExperiencesApi,
        currentPage,
        perPage,
        [queryDateRange[0], queryDateRange[1]],
        debouncedSearch,
        labelString,
        sortByColumn,
        sortOrder,
      ),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleHeaderSort = (e: { key: string }) => {
    if (e.key !== sortByColumn) {
      setSortByColumn(e.key);
      setSortOrder('desc');
    } else {
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    }
  };

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setCurrentPage(1);
    setSearch(value);
  };

  const handleLabelSelect = (selectedLabels: string[], labelRule: LABEL_RULE) => {
    setLabels(selectedLabels);
    setLabelRule(labelRule);
  };

  const handleDateRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;

    if (hasFullRange) {
      const endOfDay = new Date(newDate[1] ?? new Date());
      if (newDate.length < 3 || (newDate.length >= 3 && newDate[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59);
      }

      setQueryDateRange([newDate[0], endOfDay]);
    }
  };

  return (
    <div className={CLASSES.CARD}>
      <div className={CLASSES.CARD_HEADER}>
        <h2>Content</h2>
        <div className={CLASSES.DATE_RANGE}>
          {dateRange[2] === 0
            ? `${moment().format('MMM DD, YYYY')}`
            : `${moment(dateRange[0]).format('MMM DD, YYYY')} - ${moment(dateRange[1]).format('MMM DD, YYYY')}`}
        </div>
        <div>
          <FlightDateRangePicker
            className={CLASSES.DATE_RANGE_DD}
            value={dateRange}
            onChange={handleDateRangeChange}
            maxDate={new Date()}
            includeAllTime={false}
            includeToday
            isIncludeCustom
            replaceCustomRangeLabel={false}
          />
        </div>
        <FilterByLabel onLabelSelect={handleLabelSelect} />
      </div>
      {isLoading && (
        <div className={CLASSES.SPINNER}>
          <LoadingIcon />
        </div>
      )}
      <div className={CLASSES.SEARCH_WRAPPER}>
        <FlightTextInput
          onChange={handleSearchChange}
          value={search}
          placeholderText="Search"
          iconInput="search"
          width="280px"
          hasClearIcon
        />
      </div>
      {status === 'success' && (
        <>
          <div className={CLASSES.TABLE_WRAPPER}>
            {labels.length > 0 && (
              <div className={CLASSES.LABEL_LIST}>
                {'Labels: '}
                {labelRule === LABEL_RULE.CONTAINS_ALL_OF ? 'contains all of ' : 'contains any of '}
                {labels.map((label) => (
                  <span className={CLASSES.LABEL} key={label}>
                    {label}
                  </span>
                ))}
              </div>
            )}
            <FlightTable
              tableHeaders={tableHeaders}
              tableData={data.contentsEngagement.map((content) => {
                const contentData = data.contentsData.find((contentData) => contentData.id === content.contentId);
                const template = data.templates.find((template) => template.id === contentData?.templateId);
                return {
                  key: content.contentId,
                  name:
                    contentData?.localizations?.[defaultLang].name ||
                    contentData?.localizations?.[defaultLang].title ||
                    content.contentId ||
                    '----',
                  type: template?.localizations?.[defaultLang].name || contentData?.templateId || '----',
                  total_views: content.totalViews.toLocaleString(),
                  unique_views: content.uniqueViews.toLocaleString(),
                  unique_engagements: content.uniqueEngagements.toLocaleString(),
                  unique_engagement_rate: `${(content.engagementRate * 100).toFixed(2)}%`,
                  unique_fulfillment: content.uniqueFulfillments.toLocaleString(),
                  unique_fulfillment_rate: `${(content.fulfillmentRate * 100).toFixed(2)}%`,
                };
              })}
              sortByKey={sortByColumn}
              sortOrder={sortOrder}
              handleHeaderSort={handleHeaderSort}
              hasPaginationBeforeTable={false}
              hasPaginationAfterTable={!!data.contentsEngagement.length}
              paginationProps={{
                totalPageNumber: Math.ceil(data.pagination.totalRecords / perPage) || 1,
                currentPageNumber: currentPage,
                rowsPerPageOptions: [5, 10, 15, 20],
                currentRowsPerPage: perPage,
                handlePageChange: (page: number) => setCurrentPage(page),
                handleRowsPerPageChange: (num: number) => setPerPage(num),
              }}
              isLoading={isFetching}
            />
            {!!!data.contentsEngagement.length && !isFetching && (
              <div className={CLASSES.EMPTY_DATA}>
                {getIcon('search', {})}
                No results found!
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Contents;
