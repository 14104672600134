import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import './App.scss';

// Only initialize Sentry for builds
if (process.env.REACT_APP_BUILD_ENVIRONMENT && process.env.SENTRY_STATUS === 'enabled') {
  Sentry.init({
    release: `${process.env.REACT_APP_SENTRY_RELEASE}`,
    environment: `${process.env.REACT_APP_BUILD_ENVIRONMENT}`,
    dsn: 'https://27cafa4fbeb440f58a450334d02ae697@o426285.ingest.sentry.io/5743421',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  );
}

// comment in for PWA with service worker in production mode
// registerServiceWorker();
