import { Language } from 'interface/settings/settings.interface';

export const SettingActionType = {
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_PREVIEW_LANGUAGE: 'SET_PREVIEW_LANGUAGE',
};

export interface SettingState {
  languages: Language[];
  isInitialized: boolean;
  previewTextLanguage: string;
  previewTextDirection: string;
}

export interface SetPluginAction {
  type: typeof SettingActionType.SET_LANGUAGES;
  payload: {
    languages: Language[];
  };
}
