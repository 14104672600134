import { useMutation, useQuery } from '@tanstack/react-query';

import { ZoneConfig } from 'pages/Zones/types';
import { useMemo } from 'react';
import ZoneAPI from 'services/api/zone.api';

const QUERY_KEY = 'zoneconfigurations';

async function getZoneConfig(zoneService: ZoneAPI): Promise<ZoneConfig> {
  try {
    const zoneConfig = await zoneService.getZoneConfig();
    return zoneConfig;
  } catch {
    // ToDo: Error Handling
    return { id: '', zones: [] };
  }
}

function useZoneConfig() {
  const zoneService = useMemo(() => new ZoneAPI(), []);

  const { data, isLoading, isError } = useQuery<ZoneConfig>({
    queryKey: [QUERY_KEY],
    queryFn: () => getZoneConfig(zoneService),
  });

  const mutation = useMutation({
    mutationFn: (zoneConfig: ZoneConfig) => {
      if (zoneConfig.id) {
        return zoneService.updateZoneConfig(zoneConfig, zoneConfig.id);
      } else {
        delete (zoneConfig as Partial<ZoneConfig>).id;
        return zoneService.createZoneConfig(zoneConfig);
      }
    },
  });

  return {
    zoneConfigData: data ?? { id: '', zones: [] },
    isZoneConfigLoading: isLoading,
    isZoneConfigError: isError,
    zoneConfigMutation: mutation,
    queryKey: QUERY_KEY,
  };
}

export default useZoneConfig;
