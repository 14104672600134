export type TNavigationButton = {
  label: string;
  cta: () => void;
  isDisabled?: boolean;
};

export enum OrderByMapValue {
  MODIFIED_AT = 'modified_at',
  AVAILABLITY_FROM = 'availability_from',
  AVAILABLITY_TO = 'availability_to',
}

export enum OrderDirection {
  ACENDING = 'asc',
  DESCENDING = 'desc',
}

export enum ZoneModuleTabKeys {
  ZONE = 'zone',
  INFO = 'modinfo',
  LAYOUT = 'modlayout',
}

export enum MODULE_LAYOUTS {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  EXPOSE = 'expose',
}
