import React, { useState } from 'react';

// Custom imports
import { EVENT_KEYS } from 'types/events';
import { getIcon } from '@flybits/design-system';
import { JourneyState } from 'store/journey/journey.type';
import { SmartTargetStatusType } from 'interface/smart-targeting/smart-targeting.interface';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import ContextDataSlideOut from 'components/ExperienceCanvas/ContextDataSlideOut/ContextDataSlideOut';
import FileUploadDropdown from 'components/ExperienceCanvas/FileUploadDropdown/FileUploadDropdown';
import LabelsSlidingPanel from 'components/ExperienceCanvas/LabelsSlidePanel/LabelsSlidePanel';
import Schedule from 'components/ExperienceCanvas/Schedule/Schedule';
import ScheduleIcon from 'pages/ExperienceCanvas/assets/icons/ScheduleIcon';
import Settings from 'components/ExperienceCanvas/Settings/Settings';
import SettingsIcon from 'pages/ExperienceCanvas/assets/icons/SettingsIcon';
import SmartTargeting from 'components/ExperienceCanvas/SmartTargeting/SmartTargeting';
import SmartTargetingData from '../SmartTargetingData/SmartTargetingData';
import useFeatureFlag from 'hooks/useFeatureFlag';

// Stylings
import './ActionsHeader.scss';

type FeatureHeaderType = {
  journeyInstance: JourneyState;
};

const headerIconFill = '#2371f1';

const ActionsHeader: React.FC<FeatureHeaderType> = ({ journeyInstance }) => {
  const { flags } = useFeatureFlag();
  const [isLabelsPanelOpen, setIsLabelsPanelOpen] = useState(false);
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false);
  const [isSchedulePanelOpen, setIsSchedulePanelOpen] = useState(false);
  const [isSmartTargetingPanelOpen, setIsSmartTargetingPanelOpen] = useState(false);
  const [isContextDataSlideOutOpen, setIsContextDataSlideOutOpen] = useState(false);
  const isListBasedTemplate = useSelector(
    (state) =>
      state.te.journey.templateName?.toLowerCase().includes('list-based') ||
      state.te.journey.steps.reduce((prev, curr) => prev || curr.type === 'list-based', false) ||
      false,
  );
  const isAOEligible =
    !!journeyInstance.audienceOptimizationEligible ||
    !!journeyInstance.audienceOptimization?.audienceOptimizationEligible;
  const [smartTargetingStatus, setSmartTargetingStatus] = useState<SmartTargetStatusType>('');

  const toggleIsLabelsPanelOpen = () => {
    setIsLabelsPanelOpen((prevState) => !prevState);
  };

  const toggleIsSettingsPanelOpen = () => {
    setIsSettingsPanelOpen((prevState) => !prevState);
  };

  const toggleIsSchedulePanelOpen = () => {
    setIsSchedulePanelOpen((prevState) => !prevState);
  };
  const toggleIsSmartTargetingPanelOpen = () => {
    setIsSmartTargetingPanelOpen((prevState) => !prevState);
  };

  const toggleIsContextDataSlideOutOpen = () => {
    setIsContextDataSlideOutOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="overview-container__header">
        <div className="overview-container__header__left-section">
          {/* SCHEDULER OPTION */}
          <div
            className="overview-header-item-container"
            aria-label="open experience schedule editor"
            tabIndex={0}
            role="button"
            onClick={() => {
              toggleIsSchedulePanelOpen();
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === EVENT_KEYS.ENTER) {
                toggleIsSchedulePanelOpen();
              }
            }}
          >
            <ScheduleIcon fill={headerIconFill} />
          </div>
          {/* <div className="overview-header-item-container overview-header-item-container--separator"></div> */}
          {/* LABELS OPTION */}
          <div
            className="overview-header-item-container"
            aria-label="open experience labels"
            tabIndex={0}
            role="button"
            onClick={() => {
              toggleIsLabelsPanelOpen();
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === EVENT_KEYS.ENTER) {
                toggleIsLabelsPanelOpen();
              }
            }}
          >
            {getIcon('tag', { fill: headerIconFill })}
          </div>
          {/* SETTINGS OPTION */}
          {flags['tx_enable_experience_settings_in_actions_header'] && (
            <div
              className="overview-header-item-container"
              aria-label="open experience settings"
              tabIndex={0}
              role="button"
              onClick={() => {
                toggleIsSettingsPanelOpen();
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === EVENT_KEYS.ENTER) {
                  toggleIsSettingsPanelOpen();
                }
              }}
            >
              <SettingsIcon fill={headerIconFill} />
            </div>
          )}
        </div>

        {/* Right Container */}
        <div className="overview-container__header__right-section secondary__header">
          {/* UPLOAD CONTEXT DATA */}
          {isListBasedTemplate && (
            <FileUploadDropdown title="Upload Context Data" onViewAllClick={toggleIsContextDataSlideOutOpen} />
          )}

          {/* SMART TARGETTING */}
          {isAOEligible && (
            <SmartTargetingData
              journeyInstance={journeyInstance}
              toggleIsSmartTargetingPanelOpen={toggleIsSmartTargetingPanelOpen}
              status={smartTargetingStatus}
            />
          )}
        </div>
      </div>
      <LabelsSlidingPanel isOpen={isLabelsPanelOpen} onClickClose={toggleIsLabelsPanelOpen} />
      <Settings isOpen={isSettingsPanelOpen} onClickClose={toggleIsSettingsPanelOpen} />
      <Schedule isOpen={isSchedulePanelOpen} onClickClose={toggleIsSchedulePanelOpen} />
      {isAOEligible && (
        <SmartTargeting
          isOpen={isSmartTargetingPanelOpen}
          onClickClose={toggleIsSmartTargetingPanelOpen}
          journeyInstance={journeyInstance}
          status={smartTargetingStatus}
          setStatus={setSmartTargetingStatus}
        />
      )}
      <ContextDataSlideOut isOpen={isContextDataSlideOutOpen} onClickClose={toggleIsContextDataSlideOutOpen} />
    </>
  );
};

export default ActionsHeader;
