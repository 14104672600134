import { SettingActionType, SettingState } from 'store/settings/settings.type';

const initialState: SettingState = {
  languages: [],
  isInitialized: false,
  previewTextDirection: 'auto',
  previewTextLanguage: 'en',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: SettingState = initialState, action: any) => {
  switch (action.type) {
    case SettingActionType.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload.languages,
        isInitialized: true,
        previewTextDirection: 'auto',
        previewTextLanguage: 'en',
      };
    case SettingActionType.SET_PREVIEW_LANGUAGE:
      return {
        ...state,
        previewTextDirection: action.payload.previewTextDirection,
        previewTextLanguage: action.payload.previewTextLanguage,
      };
    default:
      return {
        ...state,
      };
  }
};
