import React, { useCallback, useState } from 'react';
import ZoneModuleConfirmationModal from 'components/Zones/v2/ZoneModuleConfirmationModal/ZoneModuleConfirmationModal';

export type ConfirmationDialogProps = {
  theme: 'add' | 'remove';
  icon: JSX.Element;
  title: string;
  description: string;
  primaryAction: {
    value: string;
    onClickHandler?: () => void;
  };
  secondaryAction: {
    value: string;
    onClickHandler?: () => void;
  };
};

const useConfirm = (props: ConfirmationDialogProps) => {
  const { title, description, icon, theme, primaryAction, secondaryAction } = props;
  const [promise, setPromise] = useState<any>(null);

  const confirm = useCallback(
    async () =>
      new Promise((resolve) => {
        setPromise({ resolve });
      }),
    [],
  );

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = useCallback(() => {
    promise?.resolve(true);
    primaryAction.onClickHandler?.();
    handleClose();
  }, [promise, primaryAction]);

  const handleCancel = useCallback(() => {
    promise?.resolve(false);
    secondaryAction.onClickHandler?.();
    handleClose();
  }, [promise, secondaryAction]);

  // You could replace the Dialog with your library's version
  const ConfirmationDialog = useCallback(() => {
    return (
      <ZoneModuleConfirmationModal
        isOpen={promise !== null}
        closeModal={handleCancel}
        theme={theme}
        icon={icon}
        title={title}
        description={description}
        primaryAction={{
          value: primaryAction.value ?? 'Continue',
          onClickHandler: handleConfirm,
        }}
        secondaryAction={{ value: secondaryAction.value ?? 'Cancel', onClickHandler: handleCancel }}
      />
    );
  }, [
    icon,
    theme,
    title,
    description,
    primaryAction.value,
    secondaryAction.value,
    promise,
    handleCancel,
    handleConfirm,
  ]);

  return [ConfirmationDialog, confirm];
};

export default useConfirm;
