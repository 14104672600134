import React from 'react';
import { FlightButton, FlightSelect, FlightTextInput } from '@flybits/design-system';
import './MerchantForm.scss';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

const CLASS_MAIN = 'merchant-portal';
const CLASSES = {
  FORM: `${CLASS_MAIN}__form`,
  CONTENT: `${CLASS_MAIN}__form__content`,
  CONTENT_LEFT: `${CLASS_MAIN}__form__content__left`,
  CONTENT_RIGHT: `${CLASS_MAIN}__form__content__right`,
  CONTENT_FOOTER: `${CLASS_MAIN}__form__content__footer`,
  CONTENT_GROUP: `${CLASS_MAIN}__form__content__group`,
};

const initialValues = {
  name: '',
  id: '',
  category: null,
  logo: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  contact: {
    name: '',
    phone: '',
    email: '',
  },
};
interface FormProps {
  onSubmit: (values: any) => void;
}
interface SelectOptionProps {
  key: string;
  name: string;
}
// TODO: create a complete validation. This is just for testing purposes
export const nameRegex = /^(?![\s.]+$)[a-zA-Z-\s.]+$/;
// Gift for you doing validation:
// This code will match a US or Canadian phone number, and will also make sure that it is a valid area code and exchange
export const phoneRegex = /\(?\d+\)?[-.\s]?\d+[-.\s]?\d+/;
const validationSchema = Yup.object().shape({
  name: Yup.string().required('merchant name cannot be empty').matches(nameRegex, 'Invalid merchant name'),
});
function MerchantForm(props: FormProps) {
  const { onSubmit } = props;
  const history = useHistory();
  const { pid } = useParams<{ pid: string }>();

  const handleCancel = () => {
    history.push(`/project/${pid}/merchant-portal`);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      enableReinitialize
      onSubmit={async (values) => onSubmit(values)}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form className={CLASSES.FORM} autoComplete="off">
          <header>
            <h2>Merchant Profile</h2>
            <p>Please provide the following information about the merchant</p>
          </header>
          <main className={CLASSES.CONTENT}>
            <section>
              <div className={CLASSES.CONTENT_LEFT}>
                <h3>About the merchant</h3>
                <p>
                  Please provide the following information about the merchant to help us identify and categorize the
                  business correctly
                </p>
              </div>
              <div className={CLASSES.CONTENT_RIGHT}>
                <label htmlFor="merchant-name">
                  Merchant name<span>*</span>
                </label>
                <Field
                  type="text"
                  name="name"
                  width="100%"
                  value={values.name}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.name}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.name}
                    </>
                  }
                />
                <span>Enter the official name of the merchant</span>
                <label htmlFor="merchant-id">Merchant ID</label>
                <Field
                  type="number"
                  name="id"
                  width="100%"
                  value={values.id}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.id}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.id}
                    </>
                  }
                />
                <span>
                  Enter the unique Merchant ID. This is a number the merchant should have this number assigned by their
                  acquirer
                </span>
                <label htmlFor="merchant-category">Category</label>
                <Field
                  type="select"
                  name="category"
                  hasLabelAnimation
                  label="Select a category"
                  arialabel="Select a category"
                  width="100%"
                  // TODO: This should be filled with "real" data
                  options={[
                    { key: 'a', value: 1, name: 'Category a' },
                    { key: 'b', value: 2, name: 'Category b' },
                    { key: 'c', value: 3, name: 'Category c' },
                    { key: 'd', value: 4, name: 'Category d' },
                  ]}
                  // TODO: This is temporary selection check
                  selected={values.category ? { key: values.category, name: `Category ${values.category}` } : null}
                  dropdownMaxHeight="250px"
                  as={FlightSelect}
                  handleOptionClick={(value: SelectOptionProps) => {
                    setFieldValue('category', value.key);
                  }}
                />
                <span>Select the category that best describes the merchant</span>
                <label htmlFor="merchant-icon">Merchant logo</label>
                <div>Icon upload goes here</div>
                <span>Maximum file size is X MB</span>
                <label htmlFor="merchant-phone">
                  Phone number<span>*</span>
                </label>
                <Field
                  type="number"
                  name="phone"
                  width="100%"
                  value={values.phone}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.phone}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.phone}
                    </>
                  }
                />
                <span>Enter the merchant contact number here</span>
                <label htmlFor="merchant-address">
                  Address<span>*</span>
                </label>
                <Field
                  type="text"
                  name="address"
                  width="100%"
                  value={values.address}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.address}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.address}
                    </>
                  }
                />
                <span>Enter the merchant address here</span>
                <div className={CLASSES.CONTENT_GROUP}>
                  <label htmlFor="merchant-city">
                    City<span>*</span>
                    <Field
                      type="text"
                      name="city"
                      width="100%"
                      value={values.city}
                      as={FlightTextInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={!!errors.city}
                      errorMessage={
                        <>
                          <b>Error:</b> {errors.city}
                        </>
                      }
                    />
                  </label>
                  <label htmlFor="merchant-state">
                    State<span>*</span>
                    <Field
                      type="select"
                      name="state"
                      hasLabelAnimation
                      label="Select a state"
                      arialabel="Select a state"
                      width="100%"
                      // TODO: This should be filled with "real" data
                      options={[
                        { key: 'a', value: 1, name: 'State a' },
                        { key: 'b', value: 2, name: 'State b' },
                        { key: 'c', value: 3, name: 'State c' },
                        { key: 'd', value: 4, name: 'State d' },
                      ]}
                      // TODO: This is temporary selection check
                      selected={values.state ? { key: values.state, name: `State ${values.state}` } : null}
                      dropdownMaxHeight="250px"
                      as={FlightSelect}
                      handleOptionClick={(value: SelectOptionProps) => {
                        setFieldValue('state', value.key);
                      }}
                    />
                  </label>
                  <label htmlFor="merchant-city">
                    Zip code<span>*</span>
                    <Field
                      type="text"
                      name="zip"
                      width="100%"
                      value={values.zip}
                      as={FlightTextInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={!!errors.zip}
                      errorMessage={
                        <>
                          <b>Error:</b> {errors.zip}
                        </>
                      }
                    />
                  </label>
                </div>
              </div>
            </section>
            <hr />
            <section>
              <div className={CLASSES.CONTENT_LEFT}>
                <h3>Merchant Contact Person</h3>
                <p>
                  Enter the details of the primary contact person responsible for handling any needs or communications
                </p>
              </div>
              <div className={CLASSES.CONTENT_RIGHT}>
                <label htmlFor="merchant-contact-name">Contact person</label>
                <Field
                  type="text"
                  name="contact.name"
                  width="100%"
                  value={values.contact.name}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.contact?.name}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.contact?.name}
                    </>
                  }
                />
                <span>Enter the name of the primary contact person</span>
                <label htmlFor="merchant-contact-phone">Phone number</label>
                <Field
                  type="text"
                  name="contact.phone"
                  width="100%"
                  value={values.contact?.phone}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.contact?.phone}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.contact?.phone}
                    </>
                  }
                />
                <span>Enter the phone number of the primary contact person</span>
                <label htmlFor="merchant-contact-email">Email</label>
                <Field
                  type="email"
                  name="contact.email"
                  width="100%"
                  value={values.contact?.email}
                  as={FlightTextInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.contact?.email}
                  errorMessage={
                    <>
                      <b>Error:</b> {errors.contact?.email}
                    </>
                  }
                />
                <span>Enter the email address of the primary contact person</span>
              </div>
            </section>
            <section className={CLASSES.CONTENT_FOOTER}>
              <FlightButton theme="secondary" onClick={handleCancel} label={`Cancel`} />
              <FlightButton theme="primary" onClick={handleSubmit} label={`Next`} disabled={!!errors.name} />
            </section>
          </main>
        </form>
      )}
    </Formik>
  );
}

export default MerchantForm;
