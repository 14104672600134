import './PublishChangesBanner.scss';

import React, { useContext, useEffect, useState } from 'react';
import { FlightButton, getIcon } from '@flybits/design-system';
import UnsavedPrompt from '../UnsavedPrompt/UnsavedPrompt';
import PrioritizationContext, {
  initialPublishChangesBannerInfo,
} from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import IconPublishChangesZonesModules from 'pages/ZonesV2/Icons/IconPublishChangesZonesModules';
import IconRemoveZonesModules from 'pages/ZonesV2/Icons/IconRemoveZonesModules';
import ZoneModuleConfirmationModal from 'components/Zones/v2/ZoneModuleConfirmationModal/ZoneModuleConfirmationModal';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

const infoIcon = getIcon('infoFilled', {
  width: 18,
  height: 18,
  fill: '#fff',
});
const errorIcon = getIcon('warning', { width: 18, height: 18, fill: '#fff' });

export default function PublishChangesBanner() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    publishChangesBannerInfo: { title, errorMessage, show, didAnimationEnd },
    setPublishChangesBannerInfo,
    publishZoneConfigs,
    resetZoneConfigs,
    numUnsavedChanges,
    queryKey,
  } = useContext(PrioritizationContext);

  const bannerTitle = title || `You have made changes to Zones and Modules`;

  const [isZoneConfigSaving, setZoneConfigSaving] = useState(false);
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const showDiscardModal = () => setDiscardModalOpen(true);
  const closeDiscardModal = () => setDiscardModalOpen(false);
  const showPublishModal = () => setPublishModalOpen(true);
  const closePublishModal = () => setPublishModalOpen(false);

  const handleDiscardModalPrimaryAction = () => {
    resetZoneConfigs && resetZoneConfigs();
    closeDiscardModal();
    setPublishChangesBannerInfo(initialPublishChangesBannerInfo);
  };
  const handlePublishModalPrimaryAction = async () => {
    try {
      setZoneConfigSaving(true);

      await publishZoneConfigs();
      await queryClient.invalidateQueries({ queryKey: [queryKey] });
      dispatch(
        showSnackbar({
          content: `Changes to zones and modules have been published`,
          type: 'success',
        }),
      );
    } catch (e: unknown) {
      const errorMessage = isAxiosError(e) ? e.response?.data?.error?.exceptionMessage : undefined;
      dispatch(
        showSnackbar({
          content: `Your zones and modules could not be saved! \n Error: ${errorMessage ?? 'unknown'}`,
          type: 'error',
        }),
      );
    } finally {
      setZoneConfigSaving(false);
      closePublishModal();
      setPublishChangesBannerInfo(initialPublishChangesBannerInfo);
    }
  };

  const [bannerAnimationEnd, setBannerAnimationEnd] = useState(false);
  const [bannerTextAnimationEnd, setBannerTextAnimationEnd] = useState(false);
  const [bannerButtonsAnimationEnd, setBannerButtonsAnimationEnd] = useState(false);

  const handlePublishBannerAnimationEnd = () => setBannerAnimationEnd(true);
  const handlePublishBannerTextAnimationEnd = () => setBannerTextAnimationEnd(true);
  const handlePublishBannerButtonsAnimationEnd = () => setBannerButtonsAnimationEnd(true);

  useEffect(() => {
    if (bannerAnimationEnd && bannerTextAnimationEnd && bannerButtonsAnimationEnd) {
      setPublishChangesBannerInfo((bannerInfo) => ({ ...bannerInfo, didAnimationEnd: true }));
    }
  }, [bannerAnimationEnd, bannerTextAnimationEnd, bannerButtonsAnimationEnd, setPublishChangesBannerInfo]);

  return (
    show && (
      <div
        className={`publish-banner ${!!errorMessage ? 'publish-banner--error' : ''}${
          didAnimationEnd ? 'publish-banner--no-animation' : ''
        }`}
        onAnimationEnd={handlePublishBannerAnimationEnd}
      >
        <UnsavedPrompt
          when={numUnsavedChanges > 0}
          unblockPaths={['/zones/v2']}
          dialogProps={{
            primaryAction: {
              value: 'Discard changes',
              onClickHandler: () => setPublishChangesBannerInfo(initialPublishChangesBannerInfo),
            },
          }}
        />
        <ZoneModuleConfirmationModal
          isOpen={isDiscardModalOpen}
          closeModal={closeDiscardModal}
          theme={'remove'}
          icon={<IconRemoveZonesModules />}
          title={'Discard Changes?'}
          description={'This will undo all changes that have been made since the last time you published.'}
          primaryAction={{
            value: 'Yes, continue',
            onClickHandler: handleDiscardModalPrimaryAction,
          }}
          secondaryAction={{
            value: 'Cancel',
            onClickHandler: closeDiscardModal,
          }}
        />
        <ZoneModuleConfirmationModal
          isOpen={isPublishModalOpen}
          closeModal={closePublishModal}
          theme={'add'}
          icon={<IconPublishChangesZonesModules />}
          title={'Publish Changes'}
          description={
            'Changes you have made may not be saved. Before leaving this page, please publish your changes first.'
          }
          primaryAction={{
            value: 'Publish changes',
            onClickHandler: handlePublishModalPrimaryAction,
          }}
          secondaryAction={{
            value: 'Continue without publishing',
            onClickHandler: closePublishModal,
          }}
          isLoading={isZoneConfigSaving}
        />
        <div
          className={`publish-banner__text ${didAnimationEnd ? 'publish-banner__text--no-animation' : ''}`}
          onAnimationEnd={handlePublishBannerTextAnimationEnd}
        >
          <span className="publish-banner__icon" aria-hidden>
            {!!errorMessage ? errorIcon : infoIcon}
          </span>{' '}
          {!!errorMessage ? errorMessage : bannerTitle}
        </div>
        <div
          className={`publish-banner__buttons ${didAnimationEnd ? 'publish-banner__buttons--no-animation' : ''}`}
          onAnimationEnd={handlePublishBannerButtonsAnimationEnd}
        >
          <FlightButton onClick={showDiscardModal} theme={'primary'} label="Discard changes" size="small" />
          <FlightButton
            onClick={showPublishModal}
            theme={'secondary'}
            label="Publish changes"
            size="small"
            disabled={!!errorMessage}
          />
        </div>
      </div>
    )
  );
}
