export const LABELS_SORT_FIELDS_MAPPER = {
  modified_at: 'Date Modified',
  availability_from: 'Start Date',
  availability_to: 'Expiry Date',
  ascending: 'Oldest to most recent',
  descending: 'Most recent to oldest',
};

import { OrderByMapValue, OrderDirection } from './types';

const ORDER_BY_LIST = [
  { key: OrderByMapValue.MODIFIED_AT, name: LABELS_SORT_FIELDS_MAPPER.modified_at },
  { key: OrderByMapValue.AVAILABLITY_FROM, name: LABELS_SORT_FIELDS_MAPPER.availability_from },
  { key: OrderByMapValue.AVAILABLITY_TO, name: LABELS_SORT_FIELDS_MAPPER.availability_to },
];

const ORDER_DIRECTION_LIST = [
  { key: OrderDirection.ACENDING, name: LABELS_SORT_FIELDS_MAPPER.ascending },
  { key: OrderDirection.DESCENDING, name: LABELS_SORT_FIELDS_MAPPER.descending },
];

const toOrderDirection = (isDecsending: boolean): { key: string; name: string } => {
  return isDecsending
    ? { key: OrderDirection.DESCENDING, name: LABELS_SORT_FIELDS_MAPPER.descending }
    : { key: OrderDirection.ACENDING, name: LABELS_SORT_FIELDS_MAPPER.ascending };
};

const toOrderBy = (orderBy: string | null): { key: string; name: string } => {
  switch (orderBy) {
    case OrderByMapValue.AVAILABLITY_FROM:
      return { key: OrderByMapValue.AVAILABLITY_FROM, name: LABELS_SORT_FIELDS_MAPPER.availability_from };
    case OrderByMapValue.AVAILABLITY_TO:
      return { key: OrderByMapValue.AVAILABLITY_TO, name: LABELS_SORT_FIELDS_MAPPER.availability_to };
    case OrderByMapValue.MODIFIED_AT:
      return { key: OrderByMapValue.MODIFIED_AT, name: LABELS_SORT_FIELDS_MAPPER.modified_at };
    default:
      return { key: OrderByMapValue.MODIFIED_AT, name: LABELS_SORT_FIELDS_MAPPER.modified_at };
  }
};

export { toOrderBy, ORDER_BY_LIST, ORDER_DIRECTION_LIST, toOrderDirection };
