import { Merchant, Offer } from 'pages/MerchantPortal/merchant-portal.types';
import BaseAPI from './base.api';
import { sleep } from 'helpers/common.helper';

class MerchantAPI extends BaseAPI {
  private ROUTES = {
    merchants: `kernel/journey/merchants`,
    offers: `kernel/journey/merchants/:merchantId/offers`,
  };

  private queryParams = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  getMerchants(params = {}): Promise<Merchant[]> {
    const queryParams = { ...this.queryParams, ...params };
    return this.GET(this.ROUTES.merchants, queryParams);
  }

  getOffers(merchantId: string): Promise<Offer[]> {
    return this.GET(this.buildURL(this.ROUTES.offers, { merchantId }), this.queryParams);
  }

  async getMerchantsMock(): Promise<Merchant[]> {
    await sleep(1200);
    const { default: merchants } = await import('../../pages/MerchantPortal/mocks/mock-merchants-and-offers');
    return merchants as Merchant[];
  }

  async getOffersMock(): Promise<Offer[]> {
    await sleep(1200);
    const { default: offers } = await import('../../pages/MerchantPortal/mocks/mock-offers');
    return offers as Offer[];
  }
}

const merchantService = new MerchantAPI();

export default merchantService;
