import React from 'react';
import './Breadcrumbs.scss';
interface BreadCrumbItems {
  icon: JSX.Element | null;
  text: string;
  forceOutline?: boolean;
}
interface BreadCrumbProps {
  items: BreadCrumbItems[];
  selectedStep?: number;
}
const CLASS_MAIN = 'breadcrumbs';
const CLASSES = {
  WRAPPER: `${CLASS_MAIN}__wrapper`,
  ITEM: `${CLASS_MAIN}__item`,
  ITEM_CONTENT: `${CLASS_MAIN}__item__content`,
  ICON: `${CLASS_MAIN}__item__icon`,
};
export default function Breadcrumbs(props: BreadCrumbProps) {
  const { items, selectedStep = 1 } = props;
  return (
    <div className={CLASS_MAIN}>
      <ul className={CLASSES.WRAPPER}>
        {items.map(({ icon, text, forceOutline }, index) => (
          <li key={index} className={`${CLASSES.ITEM}`}>
            <div className={`${CLASSES.ITEM_CONTENT} ${selectedStep === index + 1 ? 'selected' : ''}`}>
              {icon && <span className={`${CLASSES.ICON} ${forceOutline ? 'outline' : ''}`}>{icon}</span>} {index + 1}.{' '}
              {text}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
