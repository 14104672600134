import React from 'react';
import './RegisterMerchant.scss';
import FlybitsLogo from 'assets/images/flybits-logo.svg';
import MerchantForm from 'components/MerchantPortal/MerchantForm/MerchantForm';
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs';
import { getIcon } from '@flybits/design-system';

const CLASS_MERCHANT_PORTAL = 'register-merchant-flexbox';
const CLASS_INPUT_CONTAINER = `${CLASS_MERCHANT_PORTAL}__container`;
const CLASS_HEADER = `${CLASS_MERCHANT_PORTAL}__header`;
const CLASS_HEADER_INFO = `${CLASS_HEADER}__info`;
const CLASS_HEADER_TITLE = `${CLASS_HEADER}__title`;
const CLASS_HEADER_SUBTITLE = `${CLASS_HEADER}__subtitle`;
const CLASS_BREADCRUMB = `${CLASS_MERCHANT_PORTAL}__breadcrumb`;
const CLASS_BREADCRUMB_WRAPPER = `${CLASS_MERCHANT_PORTAL}__breadcrumb-wrapper`;

// NOTE: These are EXAMPLE icons. Please update to match with designs when implementing
const BREADCRUMB_ITEMS = [
  { icon: <>{getIcon('person', {})}</>, forceOutline: true, text: 'Create Merchant profile' },
  { icon: <>{getIcon('eyeOpen', {})}</>, forceOutline: true, text: 'Review and confirm' },
];

const RegisterMerchant = function () {
  return (
    <div className={CLASS_MERCHANT_PORTAL}>
      <header className={CLASS_HEADER}>
        <img alt="flybits logo" src={FlybitsLogo} />
        <div className={CLASS_HEADER_INFO}>
          <div className={CLASS_HEADER_TITLE}>Register a Local Merchant</div>
          <div className={CLASS_HEADER_SUBTITLE}>Please fill out the form below to register a new merchant.</div>
        </div>
      </header>
      <section className={CLASS_BREADCRUMB}>
        <div className={CLASS_BREADCRUMB_WRAPPER}>
          <Breadcrumbs items={BREADCRUMB_ITEMS} selectedStep={1} />
        </div>
      </section>
      <section className={CLASS_INPUT_CONTAINER}>
        <MerchantForm onSubmit={() => null} />
      </section>
    </div>
  );
};

export default RegisterMerchant;
