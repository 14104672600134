import './ZoneModuleConfirmationModal.scss';

import React, { useEffect, useRef } from 'react';
import { FlightButton, getIcon } from '@flybits/design-system';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';

const CLASS_MAIN = 'zm-confirmation-modal';
const CLASSES = {
  CHILDREN: `${CLASS_MAIN}__children`,
  CLOSE_ICON: `${CLASS_MAIN}__children__close-icon`,
  BODY: `${CLASS_MAIN}__children__body`,
  ICON: `${CLASS_MAIN}__children__body__icon`,
  CONTAINER: `${CLASS_MAIN}__children__body__container`,
  INFO: `${CLASS_MAIN}__children__body__container__info`,
  TITLE: `${CLASS_MAIN}__children__body__container__info__title`,
  DESCRIPTION: `${CLASS_MAIN}__children__body__container__info__description`,
  ACTIONS: `${CLASS_MAIN}__children__body__container__actions`,
  ACTIONS_BUTTON: `${CLASS_MAIN}__children__body__container__actions__button`,
  LOADING_CONTAINER: `${CLASS_MAIN}__loading-container`,
  LOADING_SPINNER: `${CLASS_MAIN}__loading-container__spinner`,
};

export type ZoneModuleConfirmationModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  theme: 'add' | 'remove';
  icon: JSX.Element;
  title: string;
  description: string;
  primaryAction: {
    value: string;
    onClickHandler: () => void;
  };
  secondaryAction: {
    value: string;
    onClickHandler: () => void;
  };
  isLoading?: boolean;
};

function ZoneModuleConfirmationModal(props: ZoneModuleConfirmationModalProps) {
  const { isOpen, closeModal, theme, icon, title, description, primaryAction, secondaryAction, isLoading } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef} onCancel={closeModal} className={CLASS_MAIN}>
      {isLoading && (
        <div className={CLASSES.LOADING_CONTAINER}>
          <div className={CLASSES.LOADING_SPINNER}>
            <LoadingIcon />
          </div>
        </div>
      )}
      <div className={CLASSES.CHILDREN}>
        <div className={CLASSES.CLOSE_ICON}>
          <button onClick={props.secondaryAction.onClickHandler}>{getIcon('clear', {})}</button>
        </div>
        <div className={CLASSES.BODY}>
          <div className={CLASSES.ICON}>{icon}</div>
          <div className={CLASSES.CONTAINER}>
            <div className={CLASSES.INFO}>
              <div className={CLASSES.TITLE}>{title}</div>
              <div className={CLASSES.DESCRIPTION}>{description}</div>
            </div>
            <div className={CLASSES.ACTIONS}>
              <FlightButton
                className={CLASSES.ACTIONS_BUTTON}
                label={primaryAction.value}
                ariaLabel={primaryAction.value}
                onClick={primaryAction.onClickHandler}
                theme={theme === 'add' ? 'primary' : 'minor'}
              />
              <FlightButton
                className={CLASSES.ACTIONS_BUTTON}
                label={secondaryAction.value}
                ariaLabel={secondaryAction.value}
                onClick={secondaryAction.onClickHandler}
                theme={theme === 'remove' ? 'secondary' : 'minor'}
              />
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
}

export default ZoneModuleConfirmationModal;
