import React from 'react';
import './MerchantPortal.scss';
import { useRouteMatch } from 'react-router-dom';
import useMerchantOffersMock from 'hooks/useMerchantOffersMock';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';

const CLASS_PAGE_LOADER = 'merchant-portal-page-loader';
const CLASS_MAIN = 'merchant-portal';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  EMPTY_CONTAINER: `${CLASS_MAIN}__empty-container`,
};

type MerchantRouteParams = {
  merchantId: string;
};

// ToDo: Remove after the real component for Merchant Offers is created
const MerchantOffers: React.FC<React.ComponentProps<'div'>> = () => {
  const { merchantId: routeMerchantId } = useRouteMatch<MerchantRouteParams>().params;

  const { offersData, areOffersLoading } = useMerchantOffersMock();

  if (areOffersLoading) {
    return (
      <div className={CLASS_PAGE_LOADER}>
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className={CLASS_MAIN}>
      <h2>{`Merchant Id: ${routeMerchantId}`}</h2>
      {offersData.map((offer, index) => (
        <div className={CLASSES.BODY} key={index}>
          <ul>
            <li>
              <b>{`Offer Id: ${offer.id}`}</b>
              <ul>
                <li>{`Title: ${offer.title}`}</li>
                <li>{`Description: ${offer.description}`}</li>
                <li>{`Spend Threshold: ${offer.spendThreshold}`}</li>
                <li>{`Target Budget: ${offer.targetBudget}`}</li>
              </ul>
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MerchantOffers;
