import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useJourneyInstance from 'hooks/useJourneyInstance';
import useContentCreationManager from 'hooks/useContentCreationManager';
import { showContentBox, getContentGrouping } from 'store/journey/journey.selector';
import { validateTrigger, validateActions } from 'validators/ExperienceCanvas/journey.validator';

import ToggleOptions from 'components/Shared/ToggleOptions/ToggleOptions';
import { FlightButton, FlightTextInput, FlightTooltip } from '@flybits/design-system';

import MultiActionPreview from 'components/ExperienceCanvas/MultiActionPreview/MultiActionPreview';
import PushEditorBox from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/PushEditorBox/PushEditorBox';
import ScheduleEditorBox from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/ScheduleEditorBox/ScheduleEditorBox';
import AudienceBox from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/AudienceBox';
import ContentEditorBox from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/ContentEditorBox/ContentEditorBox';
import ContentGroupingBox from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/ContentGroupingBox/ContentGroupingBox';
import UploadContextDataBox from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/UploadContextDataBox/UploadContextDataBox';
import ErrorSplash from 'components/ExperienceCanvas/ErrorSplash/ErrorSplash';
import { TIconProps } from 'pages/ExperienceCanvas/assets/types';
import { ContentListItem } from 'components/ExperienceCanvas/types';

import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import ActionsIcon from 'pages/ExperienceCanvas/assets/icons/ActionsIcon';
import TriggerIcon from 'pages/ExperienceCanvas/assets/icons/TriggerIcon';

import './TouchpointEditor.scss';
import { PushStateItem } from 'store/push/push.type';
import { TTimelineBoxProps, PreviewActionType } from 'pages/ExperienceCanvas/types';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { extractContentPreviewFields } from 'helpers/content.helper';
import { getContentListItems } from 'store/content/content.selector';
import ContentAPI from 'services/api/content.api';
import IconUploadPreview from 'components/ExperienceCanvas/IconUploadPreview/IconUploadPreview';
import { debounce } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useFeatureFlag from 'hooks/useFeatureFlag';

type TouchpointEditorRouteParams = {
  pid: string;
  id: string;
  stepidx: string;
};

type TimelineItem = {
  key: 'schedule' | 'precondition' | 'audience' | 'push' | 'content' | 'uploadContextData' | 'content-grouping';
  child: React.FC<TTimelineBoxProps>;
  shouldRender?: boolean;
};

type TimelineMap = {
  [key: string]: TimelineItem[];
};

enum TabKey {
  TRIGGER = 'trigger',
  ACTIONS = 'action',
}

type TabProps = {
  key: TabKey;
  title: string;
  description: string;
  icon: React.FC<TIconProps>;
  primaryAction: () => void;
  primaryActionLabel: string;
  secondaryAction: () => void;
  secondaryActionLabel: string;
};

const classPrefix = 'touchpoint';
const previewAreaClassName = `${classPrefix}__sidebar`;
const mainWrapperClassName = `${classPrefix}__main-wrapper`;
const mainHeaderClassName = `${classPrefix}__main-header`;
const mainFooterClassName = `${classPrefix}__main-footer`;
const mainContentClassName = `${classPrefix}__main-content`;

type TouchpointEditorProps = {
  isFromFlow: boolean;
  stepIdx: number;
  activeTabIdx: number;
  closeFlowSidePanel: () => void;
};

function TouchpointEditor(props: Partial<TouchpointEditorProps> = {}) {
  const contentAPI = useMemo(() => new ContentAPI(), []);
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const {
    isFromFlow = false,
    stepIdx: flowStepIdx = -1,
    activeTabIdx: flowActiveTabIdx = -1,
    closeFlowSidePanel = () => null,
  } = props;

  const { flags } = useFeatureFlag();
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState<PreviewActionType>();
  const history = useHistory();
  const { pid: projectId, id: journeyId, stepidx: strStepIdx } = useRouteMatch<TouchpointEditorRouteParams>().params;
  const stepIdx = flowStepIdx > -1 ? flowStepIdx : parseInt(strStepIdx);

  const push = useSelector((state) => {
    const refId = state.te.journey.steps[stepIdx]?.push;
    return state.te.push.byRefId[refId] as PushStateItem;
  });
  const contents = useSelector((state) => getContentListItems(state, stepIdx).filter((c) => !!c.id));
  const isListBasedTemplate = useSelector(
    (state) =>
      state.te.journey.templateName?.toLowerCase().includes('list-based') ||
      state.te.journey.steps[stepIdx]?.type === 'list-based',
  );
  const { search } = window.location;
  const { languages, isInitialized: isLangInit, previewTextDirection, previewTextLanguage } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const selectedLang = isLangInit ? defaultLang : '';
  useContentCreationManager(true);

  const isTemplate = urlParams.get('fromTemplateLibrary')?.toLowerCase() === 'true';
  const { isLoading, error: journeyError } = useJourneyInstance(journeyId, isTemplate);

  const returnToFlowBuilder = () => {
    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          isDirty: true,
        },
      },
    });
    closeFlowSidePanel();
  };

  const returnToOverview = () => {
    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          isDirty: true,
        },
      },
    });
    history.push(`/project/${projectId}/experiences/${journeyId}/overview/${search}`);
  };

  const mainTabs: TabProps[] = [
    {
      key: TabKey.TRIGGER,
      title: 'Trigger',
      description: 'Set up the experience',
      icon: TriggerIcon,
      primaryAction: () => {
        isFromFlow ? returnToFlowBuilder() : setActiveTab(mainTabs[1]);
      },
      primaryActionLabel: 'Continue',
      secondaryAction: returnToOverview,
      secondaryActionLabel: 'Close',
    },
    {
      key: TabKey.ACTIONS,
      title: 'Actions',
      description: 'Content delivery',
      icon: ActionsIcon,
      primaryAction: isFromFlow ? returnToFlowBuilder : returnToOverview,
      primaryActionLabel: 'Continue',
      secondaryAction: () => {
        setActiveTab(mainTabs[0]);
      },
      secondaryActionLabel: 'Back',
    },
  ];

  const shouldRenderContentBox = useSelector((state) => showContentBox(state, stepIdx));
  const contentGroupingProtoId = useSelector((state) => {
    const groupingRefId = getContentGrouping(state, stepIdx);
    const groupingAction = groupingRefId ? state.te.content.byRefId[groupingRefId] : undefined;

    return groupingAction?.prototypeId;
  });

  const { data: groupingFilteredContents } = useQuery<ContentListItem[]>({
    queryKey: ['cgf', stepIdx, contentGroupingProtoId, defaultLang],
    queryFn: async () => {
      if (contentGroupingProtoId) {
        const { data: contentInstances } = await contentAPI.getContentInstances({
          data: true,
          templatePrototypeID: contentGroupingProtoId,
        });

        if (contentInstances)
          return contentInstances.map((ci) => {
            const extractedContent = extractContentPreviewFields(ci, defaultLang);
            return {
              refId: '',
              stepIdx,
              name: '',
              metadataDescription: '',
              type: 'secondary',
              isRequired: true,
              templateIdConstraint: '',
              isReadOnly: true,
              ...extractedContent,
            };
          });
      }

      return [];
    },
    enabled: !!contentGroupingProtoId,
    placeholderData: [],
  });

  const tabHasErrors = useSelector((state) => {
    const journeyState = state.te.journey;
    const step = journeyState.steps[stepIdx];
    if (!step) return { trigger: true, actions: true };
    return {
      trigger: !!validateTrigger(state, step, stepIdx).length && step.isDirty,
      action: !!validateActions(state, step).length && step.isDirty,
    };
  });

  // this should be generated
  const timelineMap: TimelineMap = useMemo(
    () => ({
      trigger: [
        {
          key: 'schedule',
          child: ScheduleEditorBox,
        },
        {
          key: 'audience',
          child: AudienceBox,
        },
        {
          key: 'uploadContextData',
          shouldRender: isListBasedTemplate,
          child: UploadContextDataBox,
        },
      ],
      action: [
        {
          key: 'push',
          child: PushEditorBox,
        },
        {
          key: 'content-grouping',
          shouldRender: !!contentGroupingProtoId,
          child: ContentGroupingBox,
        },
        {
          key: 'content',
          shouldRender: shouldRenderContentBox,
          child: ContentEditorBox,
        },
      ],
    }),
    [isListBasedTemplate, shouldRenderContentBox, contentGroupingProtoId],
  );

  const [isFullPreview, setIsFullPreview] = useState(true);
  const [activeTab, setActiveTab] = useState(flowActiveTabIdx > -1 ? mainTabs[flowActiveTabIdx] : mainTabs[0]);
  const [activeTimeline, setActiveTimeline] = useState(timelineMap[activeTab.key]);

  useEffect(() => {
    document.title = `Edit Touchpoint ${typeof stepIdx === 'number' ? stepIdx + 1 : ''} | Experience Studio @ Flybits`;
  }, [stepIdx]);

  useEffect(() => {
    if (activeTab.key === 'trigger') {
      setShowPreview(undefined);
      setIsFullPreview(true);
    }
    setActiveTimeline(timelineMap[activeTab.key]);
  }, [activeTab.key, timelineMap]);

  useEffect(() => {
    if (showPreview !== undefined) setIsFullPreview(false);
  }, [showPreview]);

  const LineElement = <div className={`${mainHeaderClassName}__line`}></div>;

  const RenderTimelineItems = (items: TimelineItem[]) =>
    items
      .filter((item) => item.shouldRender !== false)
      .map((item, index) => (
        <item.child
          key={`timeline-${item.key}`}
          stepIdx={stepIdx}
          timelineIndex={(index + 1).toString()}
          onBlurTimelineBox={setShowPreview}
        />
      ));

  const RenderMainTabs = (tabs: TabProps[]) => {
    return tabs.map((tab, idx) => {
      if (isFromFlow && idx !== flowActiveTabIdx) {
        return null;
      }

      const isActiveClass = tab.title === activeTab.title ? 'active' : '';
      const hasErrorClass = tabHasErrors[tab.key] ? `${mainHeaderClassName}__node--error` : '';
      return (
        <Fragment key={tab.title + idx}>
          {idx > 0 && LineElement}
          <button
            className={`${mainHeaderClassName}__node ${isActiveClass} ${hasErrorClass}`}
            onClick={() => setActiveTab(mainTabs[idx])}
          >
            <div className={`${mainHeaderClassName}__node-icon`}>
              <tab.icon fill="white" />
            </div>
            <div className={`${mainHeaderClassName}__node-title`}>{tab.title}</div>
            <div className={`${mainHeaderClassName}__node-subtitle`}>{tab.description}</div>
          </button>
        </Fragment>
      );
    });
  };

  const { stepName, stepDesc, stepIconUrl } = useSelector((state) => {
    const step = state.te.journey.steps[stepIdx];
    return {
      stepName: step?.name || `Touchpoint ${stepIdx + 1}`,
      stepDesc: step?.desc,
      stepIconUrl: step?.iconUrl,
    };
  });
  const [stepNameInput, setStepNameInput] = useState(stepName);

  useEffect(() => {
    setStepNameInput(stepName);
  }, [stepName]);

  const handleChangeTouchpointName = useCallback(
    (name: string) => {
      dispatch({
        type: 'UPDATE_STEP',
        payload: {
          stepIdx,
          fields: {
            name,
          },
        },
      });
    },
    [stepIdx, dispatch],
  );

  const debouncedHandleChangeTouchpointName = useMemo(
    () => debounce((title: string) => handleChangeTouchpointName(title), 500),
    [handleChangeTouchpointName],
  );

  const handleChangeTouchpointIcon = (iconUrl: string) => {
    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          iconUrl,
        },
      },
    });
  };

  return isLoading ? (
    <div className="loading-icon-container" aria-label="experience is loading">
      <LoadingIcon className="loading-icon-container__icon" />
    </div>
  ) : journeyError ? (
    <ErrorSplash error={journeyError} />
  ) : (
    <div className={`${classPrefix}`}>
      {/*Preview Area*/}
      <div className={previewAreaClassName}>
        <div className={`${previewAreaClassName}__header`}>
          {!isFromFlow && (
            <FlightButton
              className={`${previewAreaClassName}__back-button`}
              iconLeft="baselineKeyboardArrowLeft"
              onClick={returnToOverview}
              theme="minor"
              ariaLabel="exit touchpoint"
            />
          )}
          <div className={`${previewAreaClassName}__info-wrapper`}>
            {stepIconUrl || flags['tx_demo'] ? (
              <IconUploadPreview
                iconUrl={stepIconUrl || ''}
                onSuccess={(iconUrl) => iconUrl && handleChangeTouchpointIcon(iconUrl)}
              />
            ) : (
              <div className={`${previewAreaClassName}__info-index`}>
                <span>{stepIdx + 1}</span>
              </div>
            )}
            <div className={`${previewAreaClassName}__info-container`}>
              <div className={`${previewAreaClassName}__info-title`}>
                <FlightTooltip description={stepNameInput || `Touchpoint ${stepIdx + 1}`}>
                  <FlightTextInput
                    theme={'lite'}
                    className={`${previewAreaClassName}__info-title__input`}
                    value={stepNameInput}
                    placeholderText={`Touchpoint ${stepIdx + 1}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const name = e.target.value || '';
                      setStepNameInput(name);
                      debouncedHandleChangeTouchpointName(name);
                    }}
                    label={stepNameInput}
                  />
                </FlightTooltip>
              </div>
              <div className={`${previewAreaClassName}__info-subtitle`}>{stepDesc}</div>
            </div>
          </div>
        </div>
        <MultiActionPreview
          isFullPreview={isFullPreview}
          className={`${classPrefix}__preview`}
          push={{
            title: push?.title?.[previewTextLanguage || selectedLang]
              ? push.title[previewTextLanguage || selectedLang]
              : '',
            body: push?.alert?.[previewTextLanguage || selectedLang]
              ? push.alert[previewTextLanguage || selectedLang]
              : '',
            direction: previewTextDirection,
          }}
          contents={[...contents, ...(groupingFilteredContents ?? [])]}
          showPreview={showPreview}
        />
        <ToggleOptions
          className={`${previewAreaClassName}__preview-toggle`}
          optionNames={{ left: 'Real Time', right: 'Full Preview' }}
          isToggledRight={isFullPreview}
          onToggle={setIsFullPreview}
        />
        <p className={`${previewAreaClassName}__disclaimer`}>Preview only, actual rendering may vary</p>
      </div>
      {/*Main Area*/}
      <div className={mainWrapperClassName}>
        <div className={mainHeaderClassName}>{RenderMainTabs(mainTabs)}</div>
        <div
          className={`${mainContentClassName} ${tabHasErrors[activeTab.key] ? `${mainContentClassName}--error` : ''}`}
        >
          {RenderTimelineItems(activeTimeline)}
        </div>
        <div className={mainFooterClassName}>
          {!isFromFlow && (
            <FlightButton
              theme="secondary"
              type="button"
              onClick={activeTab.secondaryAction}
              label={activeTab.secondaryActionLabel}
            />
          )}
          <FlightButton
            theme="primary"
            type="button"
            onClick={activeTab.primaryAction}
            label={activeTab.primaryActionLabel}
          />
        </div>
      </div>
    </div>
  );
}

export default TouchpointEditor;
