export type TExperienceCanvasRouteParams = {
  pid: string;
  id: string;
};

export const JOURNEY_TEMPLATE_TYPES = {
  CONTENT: 'app-content',
  TRIGGERED: 'triggered',
  REMINDER: 'reminder',
  BROADCAST: 'broadcast',
  LIST_BROADCAST: 'list-broadcast',
  LIST_BASED: 'list-based',
};

export const PUSH_PAYLOAD_TYPES = {
  BASIC: 'basic',
  CONTENT: 'content',
  WEB_LINK: 'weblink',
  ACTION_LINK: 'actionlink',
  CUSTOM: 'custom',
};

export enum JOURNEY_STATUSES {
  ACTIVE = 'active',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  INACTIVE = 'inactive',
  ERROR = 'error',
}

export enum START_TIME {
  NOW = 'NOW',
}

export type CardClass = {
  headerClass: string;
  headerRowClass: string;
  titleClass: string;
  subtitleClass: string;
  iconClass: string;
  bodyClass: string;
};

// perhaps we should unify this with the actiontype actually found on journey actions
export type PreviewActionType = 'push' | 'content';

export type TTimelineBoxProps = {
  stepIdx: number;
  timelineIndex: string;
  onFocusTimelineBox?: () => void;
  onBlurTimelineBox?: (actionType?: PreviewActionType) => void;
};

export enum CONFIRMATION_MODALS_TITLES {
  SAVE_AND_ACTIVATE = 'Save and Activate Now',
  LAUNCH_ON_DATE = 'Launch on date',
  DEACTIVATE_EXPERIENCE = 'Deactivate Experience',
  CANT_EDIT_ACTIVE_EXPERIENCE = "Can't Edit Active Experience",
  CANT_SAVE_INVALID_EXPERIENCE = "Can't Save Invalid Experience",
  LAUNCH_IMMEDIATELY = 'Launch Immediately',
}

export enum ERROR_TYPES {
  INCOMPLETE = 'incomplete',
  ERROR = 'error',
}

export enum FILE_STATUS {
  PROCESSING = 'PROCESSING',
  UPLOADING = 'UPLOADING',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  PROCESSING_SUCCESS = 'PROCESSING_SUCCESS',
  UPLOADING_FAILED = 'UPLOADING_FAILED',
  UPLOADED = 'UPLOADED',
}

export type CtxFileStatus =
  | 'PROCESSING'
  | 'UPLOADING'
  | 'PROCESSING_FAILED'
  | 'PROCESSING_SUCCESS'
  | 'UPLOADING_FAILED'
  | 'UPLOADED';
