import { useQuery } from '@tanstack/react-query';
import { Offer } from 'pages/MerchantPortal/merchant-portal.types';
import merchantService from 'services/api/merchants.api';

const DEFAULT_QUERY_KEY = 'merchant-offers';

export default function useMerchantOffersMock() {
  const queryKey = [DEFAULT_QUERY_KEY];

  const { data, isLoading, isError } = useQuery<Offer[]>({
    queryKey,
    queryFn: () => merchantService.getOffersMock(),
    refetchOnWindowFocus: false,
  });

  return {
    offersData: data ?? [],
    areOffersLoading: isLoading,
    isOffersError: isError,
    queryKey,
  };
}
