import React, { useCallback, useContext, useState } from 'react';
import { FlightButton, FlightLabelSearch, getIcon } from '@flybits/design-system';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import DragAndDropIcon from 'pages/ExperienceCanvas/assets/icons/DragAndDropIcon';
import ModuleLabelIcon from 'pages/ZonesV2/Icons/ModuleLabelIcon';
import { LabelFilter } from 'pages/Zones/types';
import { EVENT_KEYS } from 'types/events';
import { DEFAULT_LABEL_LIMIT } from 'components/Zones/v2/ZoneModuleSetup/constants';
import './LabelList.scss';
import LabelFilterContext from '../LabelFilterContext/LabelFilterContext';
import { OrderByMapValue } from '../../types';

const MAIN_CLASS = 'label-filter-list';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  SEARCHBAR: `${MAIN_CLASS}__searchbar`,
  LIST: `${MAIN_CLASS}__list-container`,
  EMPTY: `${MAIN_CLASS}__list-empty-message`,
  LIST_ITEM: `${MAIN_CLASS}__list-item`,
  LI_DRAG_ICON: `${MAIN_CLASS}__list-item__drag-icon`,
  LI_INDEX: `${MAIN_CLASS}__list-item__index`,
  LI_LABEL: `${MAIN_CLASS}__list-item__label`,
  LI_REMOVE: `${MAIN_CLASS}__list-item__btn-remove`,
};

type TLabelListItemProps = {
  labelfitler: LabelFilter;
  isSelected: boolean;
  index: number;
  setSelectedLabel: React.Dispatch<React.SetStateAction<LabelFilter | null>>;
  onRemoveLabel: (label: string) => void;
};

// TODO: Build our own dnd list to end reliance on atlassian's maintenance-only library
const LabelListItem = ({ labelfitler, isSelected, index, setSelectedLabel, onRemoveLabel }: TLabelListItemProps) => {
  return (
    <Draggable draggableId={`mlf-${labelfitler.label}-row`} index={index}>
      {(provided) => (
        <div
          className={`${CLASSES.LIST_ITEM} ${isSelected ? `${CLASSES.LIST_ITEM}--selected` : ''}`}
          aria-label="open content selector"
          tabIndex={0}
          role="button"
          onClick={() => setSelectedLabel(labelfitler)}
          onKeyDown={(e) => {
            if (e.key === EVENT_KEYS.ENTER) {
              setSelectedLabel(labelfitler);
            }
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={CLASSES.LI_DRAG_ICON} {...provided.dragHandleProps}>
            <DragAndDropIcon />
          </div>
          <div className={CLASSES.LI_INDEX}>{index + 1}</div>
          <div className={CLASSES.LI_LABEL}>{labelfitler.label}</div>
          <button className={CLASSES.LI_REMOVE} onClick={() => onRemoveLabel(labelfitler.label)}>
            {getIcon('clear', {})}
          </button>
        </div>
      )}
    </Draggable>
  );
};

/**
 * TODO: LabelSearch is not ... a 1-1 replica of the featureset of the search in figma... will leave that as a future improvement
 * Also, label reordering is currently not an accessible action.
 */
const LabelList = () => {
  const { labelFilters, selectedLabelFilter, addLabelFilter, setSelectedLabelFilter, reOrder, remove } =
    useContext(LabelFilterContext);

  const [labelsToAdd, setLabelsToAdd] = useState<string[]>([]);

  const handleAddLabelFilters = useCallback(() => {
    //remove existing labels

    const doLabelFiltersExist = Array.isArray(labelFilters) && labelFilters.length > 0;
    const filteredLabels = labelsToAdd.filter((labelToAdd) => {
      return doLabelFiltersExist ? !labelFilters.find((filterLabel) => filterLabel.label === labelToAdd) : labelToAdd;
    });

    const newLabelFilters: LabelFilter[] = filteredLabels.map((label) => ({
      label,
      limit: DEFAULT_LABEL_LIMIT,
      orderBy: OrderByMapValue.MODIFIED_AT,
      orderAsc: false,
    }));

    addLabelFilter(newLabelFilters);
    setLabelsToAdd([]);
  }, [labelFilters, labelsToAdd, addLabelFilter]);

  const handleRemoveLabel = useCallback(
    (removedLabel: string) => {
      const remainingLabels = labelsToAdd.filter((label) => label !== removedLabel);
      setLabelsToAdd(remainingLabels);
    },
    [labelsToAdd],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      reOrder(result.source.index, result.destination.index);
    },
    [reOrder],
  );

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.HEADER}>
        <ModuleLabelIcon />
        <h3>Labels</h3>
      </div>
      <div className={CLASSES.SEARCHBAR}>
        <FlightLabelSearch
          labels={labelsToAdd}
          onLabelAdd={setLabelsToAdd}
          onLabelChange={() => null}
          onLabelRemove={handleRemoveLabel}
          onLabelClick={handleRemoveLabel}
        />
        <FlightButton ariaLabel={'add module label filter'} label={'Add'} onClick={handleAddLabelFilters} />
      </div>
      {labelFilters?.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="mlf-list">
            {(provided) => (
              <div className={CLASSES.LIST} ref={provided.innerRef} {...provided.droppableProps}>
                {labelFilters?.map((lf, i) => (
                  <LabelListItem
                    key={`mlf-${lf.label}`}
                    labelfitler={lf}
                    index={i}
                    isSelected={lf.label === selectedLabelFilter?.label}
                    setSelectedLabel={setSelectedLabelFilter}
                    onRemoveLabel={remove}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <p className={CLASSES.EMPTY}>Module has no label filters!</p>
      )}
    </div>
  );
};

export default LabelList;
